<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <mdb-input
            v-model.trim="sucAFiltrar"
            type="text"
            label="Nombre o RIF de la sucursal"
            class="my-2"
            outline
            @input="filtroNombre"
          />
        </div>
        <div class="col-12 col-sm-6">
          <div class="md-form md-outline outline-select my-1">
            <select
              v-model="estatusDevolucion"
              id="estado-select"
              class="custom-select"
              @change="filtroEstatus"
            >
              <option class="d-none" value="" disabled>
                Seleccione
              </option>
              <option
                value="En Espera"
              >
                En espera
              </option>
              <option
                value="Procesada"
              >
                Procesada
              </option>
            </select>
            <label
              for="estado-select"
              :class="estatusDevolucion.length ? 'label-active' : 'label-inactive'"
            >
              Estado
            </label>
          </div>
        </div>
        <div class="col-12 col-sm">
          <mdb-input
            v-model="fechaDevolucion"
            type="month"
            label="Mes"
            class="my-2"
            outline
          />
        </div>
        <div class="col-12 col-sm">
          <mdb-input
            v-model="fechaDel"
            type="date"
            label="Desde"
            class="my-2"
            outline
          />
        </div>
        <div class="col-12 col-sm">
          <mdb-input
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            class="my-2"
            outline
          />
        </div>
        <div
          v-if="sucAFiltrar || estatusDevolucion || fechaDel.length && fechaHasta.length"
          class="col-12 col-md mx-0 text-center"
        >
          <mdb-btn
            flat
            dark-waves
            class="my-2 px-2"
            icon="broom"
            @click="restablecerFiltros()"
          >
            Restablecer filtros
          </mdb-btn>
        </div>
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="devoluciones && Object.keys(devoluciones).length"
        class="table devoluciones"
      >
        <li class="encabezado sticky">
          <header class="h4-responsive">Reporte de devoluciones</header>
          <p class="descripcion">
            devoluciones listadas: {{devoluciones && Object.keys(devoluciones).length}}
          </p>
          <div class="thead claro">
            <div class="col-12 col-sm-auto">
              Día
              <small class="d-sm-block small-block">
                Mes
              </small>
            </div>
            <div class="col">
              Cliente - Sucursal
              <small class="d-sm-block small-block">
              Zona
              </small>
            </div>
            <div class="col-12 col-sm col-md-2">
              Estado
            </div>
            <div class="col-12 col-sm col-md-4">
              Vendedor
              <small class="d-sm-block small-block">
                Observación
              </small>
            </div>
          </div>
        </li>
        <!-- Mensaje para fecha sin devoluciones -->
        <li
          class="contenido cursor-pointer"
          v-for="d in devoluciones"
          :key="d.id_dev"
          @click="$router.push(`/devolucion/${d.id_dev}`)"
        >
          <div
            class="col-12 col-sm-auto columna-fecha"
            data-columna="Día / Mes"
          >
            <p>
              {{d.fec_dev.split('-')[2]}}
              <span class="small-block">
                {{mesEnLetra(d.fec_dev)}}
              </span>
            </p>
          </div>
          <div
            class="col"
            data-columna="Cliente - Sucursal / Zona"
          >
            <p>
              {{d.nom_emp}}- {{d.nom_suc}}
              <span class="small-block">
                {{d.id_dev}}, {{d.ciu_zon}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm col-md-2"
            data-columna="Estado"
          >
            <p :class="!d.est_dev ? 'text-muted font-italic user-select-none' : asignarColorEstado(d.est_dev) + ' font-weight-bold'">
              {{d.est_dev ? d.est_dev : 'Sin especificar'}}
            </p>
          </div>
          <div
            class="col-12 col-sm col-md-4"
            data-columna="Vendedor / Observación"
          >
            <p>
              {{d.nom_per}} {{d.ape_per}}
              <small :class="['d-block text-muted', {'font-italic user-select-none': !d.obs_dev}]">
                {{d.obs_dev ? d.obs_dev : 'Sin observación'}}
              </small>
            </p>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="mensaje-no-items py-3"
      >
        <font-awesome-icon
          icon="file-invoice"
          size="5x"
          class="icono"
        />
        <p class="texto">
          No hay devoluciones con el filtro seleccionado
        </p>
      </div>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import { mdbInput, mdbBtn } from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado, comprobarRutaHija } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'
export default {
  name: 'Devoluciones',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      fechaDevolucion: '',
      devoluciones: [],
      fechaDel: '',
      fechaHasta: '',
      sucAFiltrar: '',
      mes: {},
      clientes: [],
      botonDeshabilitado: false,
      estatusDevolucion: '',
      modalConfirmacionEliminar: false,
      devolucionId: '',
      alertaMensaje: {
        contenido: ''
      },
      cargando: false
    }
  },
  mounted () {
    this.fechaDevolucion = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
  },
  watch: {
    fechaDevolucion () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizarDevoluciones()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.actualizarDevoluciones()
    }
  },
  computed: {
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    }
  },
  methods: {
    actualizarDevoluciones (search) {
      this.cargando = true
      let data = search ? 'nom=' + search + '&' : ''
      data += this.sucAFiltrar ? 'nom=' + this.sucAFiltrar + '&' : ''
      data += this.estatusDevolucion ? 'est=' + this.estatusDevolucion + '&' : ''
      if (!this.fechaHasta) {
        data += this.fechaDevolucion ? 'y=' + this.fechaDevolucion.split('-')[0] + '&m=' + this.fechaDevolucion.split('-')[1] : ''
      } else {
        data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta : ''
      }
      apiPost({ s: 'devolucionesMv' }, data)
        .then((r) => {
          this.devoluciones = r.data.dev
          this.cargando = false
        })
        .catch(() => {
          this.cargando = false
        })
    },
    mesEnLetra (fechaPlana) {
      const mess = new Date(fechaPlana.substring(5, 7))
      const opt = { month: 'short' }
      const mesLetras = mess.toLocaleDateString('es-VE', opt)
      return mesLetras
    },
    restablecerFiltros () {
      this.sucAFiltrar = ''
      this.estatusDevolucion = ''
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizarDevoluciones()
    },
    filtroNombre (search) {
      if (!search) return
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.actualizarDevoluciones()
      }, 500)
    },
    filtroEstatus () {
      if (!this.estatusDevolucion) return
      this.actualizarDevoluciones()
    }
  }
}

</script>

<style lang="scss" scoped>
.table.devoluciones {
  margin: 0 auto 3rem;

  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
